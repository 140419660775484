import React from "react";
import ProcessTabs from "./processTabs";
import { processTab } from "../../../utils/constants";
import NormalProcessDigitalSigning from "./normalProcessDigitalSigning";

const NormalProcess = ({ digitalSigningProvider,dialogDefinition,onSaveDigitalSigning }) => {
    return (
        <div className="">
            <div className="border-t border-gray-200 my-5" />

            <ProcessTabs tabs={[
                { key: processTab.digitalSigning, name: 'Digital Signing', }
            ]}
                defaultTab={processTab.digitalSigning}
            >
                {
                    ({ currentTab }) => (
                        <>
                            {
                                currentTab === processTab.digitalSigning && (
                                    <NormalProcessDigitalSigning digitalSigningProvider={digitalSigningProvider} dialogDefinition={dialogDefinition} onSaveDigitalSigning={onSaveDigitalSigning}/>
                                )
                            }
                        </>
                    )
                }

            </ProcessTabs>
        </div>
    )
}

export default NormalProcess;