import React, { useState, useContext, useEffect, Fragment, useCallback } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import './styles.scss'
import { useElementDesign } from '../useElementDesign'
import { DialogModes, FormContext } from '../../contexts'
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper'
import { useElementStyle } from '../useElementStyle'
import Map, { GeolocateControl, Layer, Marker, NavigationControl, Source, useMap } from 'react-map-gl';
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/solid'
import "mapbox-gl/dist/mapbox-gl.css";
import { getDialogValueKey } from '../../utils/features'
import InlineEditing from '../../components/InlineEditing'
import { getInformationWorkflow } from '../../api/apiDefinition'
import { useParams } from 'react-router'
import { useDebounceCallback } from '@react-hook/debounce'
import { useToastAction } from '@metaforcelabs/metaforce-core'

export const clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'markers',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
  }
};

export const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'markers',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};


export const unclusteredBackgroundPointLayer = {
  id: 'unclustered-background-point',
  type: 'circle',
  source: 'markers',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#486fd1',
    'circle-radius': {
      'base': 1,
      'stops': [[0, 10], [20, 30]] 
  },
}
  // paint: {
  //   'circle-color': '#11b4da',
  //   'circle-radius': 10,
  //   'circle-stroke-width': 1,
  //   'circle-stroke-color': '#fff'
  // }
};

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'markers',
  filter: ['!', ['has', 'point_count']],
  // layout: {
  //   'text-field': '{title}',
  //   'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
  //   'text-size': 12
    
  // },
  paint: {
    'circle-color': '#11b4da',
    'circle-radius': 10,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
};

export const INITIAL_VIEW_STATE = {
  longitude: 7.6954,
  latitude:  63.4685,
  zoom: 2.8,
  minZoom: 2,
  maxZoom: 15,
  attributionControl: false
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

//dummy markers on stiro editor
const dummyMarkers = [
  { latitude: 59.911491, longitude: 10.757933, title: "Oslo" },
  { latitude: 60.391263, longitude: 5.322054, title: "Bergen" },
  { latitude: 63.430515, longitude: 10.395053, title: "Trondheim" },
  { latitude: 69.649205, longitude: 18.955324, title: "Tromsø" },
  { latitude: 70.996818, longitude: 25.720761, title: "Hammerfest" },
  { latitude: 59.3293, longitude: 18.0686, title: "Stockholm" },
  { latitude: 55.6761, longitude: 12.5683, title: "København" },
]


export default function MapElement({ data, ...props }) {  
  const formContext = useContext(FormContext)
  const { isPdfMode } = useContext(DialogModes)
  const elementDesign = useElementDesign(data.id, data);
  const { textStyle, borderAndWidthStyle ,textClassName } = useElementStyle(data);
  const [markers, setMarkers] = useState([])
  const [selected, setSelected] = useState({})
  const { dialogKey, valuesKey } = useParams();

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'white'
  const elementStyle = useElementStyle(data);
  const isLabelHidden = data?.hideLabel ?? false;
  const labelStyle = elementStyle.textStyle(data?.labelStyle)
  const { justifyContent, width: textWidth, ...textStyleProps } = textStyle(data?.text)
  const loadWorkflowAction = useToastAction()

  useEffect(() => {
    if (formContext && data.value && data.workflowRetrieveLoadId && valuesKey) {
        loadWorkflowData();
    }
    else {
      setMarkers(dummyMarkers)
      setSelected(dummyMarkers[0])
    } 
  }, []);

  const style = {
    ...textStyle(data?.text),
    ...borderAndWidthStyle(),
    backgroundColor: backgroundColorStyle,
  }

  if(style.width === '100%') {
    style.width = 'auto'
  }

  const mapRef = React.useRef(null);

  useMap({
    width: 700,
    height: 700,
    markers
  });

  const mapStyle = {
    width: '100%',
    height: '400px',
  }

  const loadWorkflowData = async () => {
    await loadWorkflowAction.executeAsync(async () => {
      const response = await getInformationWorkflow(dialogKey, valuesKey, data.workflowRetrieveLoadId, [{ property: data.property, value: data.value }])
      
      const elementData = response.find(x=> x.property == data.property);
      const json = elementData.value;
      
      if(!json) return;
      let parsedData =JSON.parse(json); 
      
      const dataType = typeof parsedData;
      if( dataType === 'object' && !Array.isArray(parsedData)){
        parsedData = [parsedData];
      }

      let workflowMarkers = [];

      parsedData.map(element => {
        if(!element?.preventDataInsert)
          workflowMarkers.push(element)
      })

      setMarkers(workflowMarkers); 
    }, "Failed to load map data")
  };

  const updateFormValues = (element) => {  
    if (data.workflowRetrieveId && markers) { 
      const location = markers.find(m => m.title === element.title)
      let formatLocationText = location.title + ', ' + location.meta
      if(!location.meta) formatLocationText = location.title
      getInformationFromWorkflow(formatLocationText, data.workflowRetrieveId);
    }
  };

  const getGeoJson = () => {
    return {
      type: "FeatureCollection",
      features: markers.map(marker => {
        return {
          type: "Feature",
          properties: {
            title: marker.title,
            description: marker.title,
          },
          geometry: {
            type: "Point",
            coordinates: [marker.longitude, marker.latitude]
          }
        }
      }
      )
    }
  }

  const getInformationFromWorkflow = useDebounceCallback((value, workflowRetrieveId) => {
    const execute = async () => {
      var response = await getInformationWorkflow(dialogKey, valuesKey, workflowRetrieveId, [{ property: data.property, value: value }])
      response.forEach(element => {
        if(!element?.preventDataInsert)
          formContext?.updateByPropertyNameValue(element.property, element.value)
      });
    }
    execute();
  });

  const changeLocation = (value, goToMarker) => {
    setSelected(value)    
    updateFormValues(value)
    formContext?.updateValue(getDialogValueKey(data), value.title)

    if(goToMarker)
      mapRef.current?.flyTo({center: [value.longitude, value.latitude], duration: 2000, zoom: 14});
  }

  const onClick = event => {
    const feature = event.features[0];

    if (feature) {
      if(feature?.layer?.id === clusterLayer.id) {
        const clusterId = feature.properties.cluster_id;

        const mapboxSource = mapRef.current.getSource('markers');

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapRef.current.easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500
          });
        });
      }
      else if (feature?.layer?.id === unclusteredPointLayer.id) {
        const location = markers.find(m => m.title === feature.properties.title)
        if(location) changeLocation(location, true)
      }

    }
  };

  const mapRefCallback = useCallback((node) => {
    if (node !== null) {
      mapRef.current = node;
    }
  }, []);

  return (
    <>
      {
        markers &&
        <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
          {
            isPdfMode ?
            <div className='w-full' style={labelStyle}>{`${data?.label ? `${elementDesign.translateTerm(data?.label)}:` : ""} ${selected?.title || ""}`}</div>
            :
            (
              <>
                <div className={`${isLabelHidden ? 'hidden' : ''}`}>
                    <InlineEditing
                      initData={data}
                      style={labelStyle}
                      classes={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${textClassName(data?.labelStyle)}`}
                      name='label'
                      id={`label-${data.id}`}
                    >
                      <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${textClassName(data?.labelStyle)}`}>
                        {elementDesign.translateTerm(data.label)}
                        {elementDesign.requiredFieldIndicator(data.requiredField)}
                        {elementDesign.translateHelpText(data)}
                      </label>
                    </InlineEditing>
                    {
                      data?.map?.hideDropdown &&
                      <div className='mt-2 mb-2'>
                        <label  style={labelStyle}>{selected?.title}</label>
                      </div>
                    }
                  </div>
                <Listbox className="" value={selected} onChange={(value) => changeLocation(value, true)}>
                  {({ open }) => (
                    <>
                      <div className={`${data?.map?.hideDropdown ? 'hidden' : 'relative'} mt-2 mb-4`}>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">{selected?.title ?? '...'}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto border border-gray-200 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {markers.map((marker, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={marker}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {marker.title}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-indigo-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                <div className='mt-2 mb-2'>
                  <label  style={labelStyle}>{selected?.meta}</label>
                </div>
              
                <div style={mapStyle} className='w-full'>
                  <Map
                    ref={mapRefCallback}
                    initialViewState={INITIAL_VIEW_STATE}
                    mapboxAccessToken={"pk.eyJ1IjoiZnN1bmRteWhyIiwiYSI6ImNsbTBkdGVlbTA4ZzIzcHM2NnRzbHp6cTEifQ.hJV8dbAE2csUN5n_RC5yaw"}
                    mapStyle="mapbox://styles/mapbox/light-v11"
                    interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
                    onClick={onClick}
                  >
                    <GeolocateControl position="bottom-right" />
                    <NavigationControl position="bottom-right" />

                    <Source
                      id="markers"
                      type="geojson"
                      data={getGeoJson()}
                      cluster={true}
                      clusterMaxZoom={14}
                      clusterRadius={50}
                    >
                      <Layer {...clusterLayer} />
                      <Layer {...clusterCountLayer} />
                      {/* <Layer {...unclusteredBackgroundPointLayer} /> */}
                      <Layer {...unclusteredPointLayer} />
                    </Source>

                    {/* {
                      markers?.filter(m => m.title !== '-')?.map((marker) => {
                        return (
                          <Marker longitude={marker.longitude} latitude={marker.latitude} style={{ cursor: "pointer" }}
                            onClick={() => changeLocation(marker)}>
                            <div className='bg-white min-w-20 px-2 text-center p-1 border rounded-sm shadow-sm'>
                              <p>{marker.title}</p> 
                            </div>
                          </Marker>
                        )
                      })
                    } */}
                    </Map>
                </div>
            </>
          )
        }
        </InlineActionbarElementWrapper>
      }
    </>
  )
}