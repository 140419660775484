import { useState, useContext, useEffect } from 'react';
import { FormContext } from '../../contexts';
import './styles.scss';
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import { DialogModes } from '../../contexts';
import InlineEditing from '../../components/InlineEditing';
import { getDialogValueKey, getElementKey } from '../../utils/features';
import { Dropdown, InputTextLine } from '../../data';
import { ElementFromType } from '../../structures/Column';

export default function Checkbox({ data, ...props }) {
  const classPrefix = "checkbox";
  const multiStep = useMultiStep(data);
  const elementDesign = useElementDesign(data.id, data);
  const { textClassName, textStyle } = useElementStyle(data);
  const { isEditMode, isPdfMode } = useContext(DialogModes);
  const formContext = useContext(FormContext);

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : 'inherit';
  const backgroundColorCheckbox = backgroundColorStyle === "#FFFFFF" || backgroundColorStyle === "#fff" ? '#878686' : backgroundColorStyle;
  const labelClass = textClassName(data?.text);
  const labelStyle = { ...textStyle(data?.text, 'left') };
  const descriptionStyle = { ...textStyle(data?.labelStyle, 'left') };
  const descriptionClass = textClassName(data?.labelStyle);

  const [value, setValue] = useState(data.value ?? data.defaultCheckedState);

  const connectedElements = [
    InputTextLine(),
    Dropdown()
  ]

  let currentValueTemp;
  let onChange;
  let inputErrorClasses;
  if (formContext) {
    const formValue = formContext?.inputValues[getDialogValueKey(data)]?.value
    if (formValue) {
      currentValueTemp = formValue?.toLowerCase() === 'true' ? true : false;
    }
    else {
      currentValueTemp = data.defaultCheckedState ? true : false;
      // formContext.updateValue(getDialogValueKey(data), currentValueTemp.toString())
    }

    inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, formValue);

    onChange = () => {
      if (currentValueTemp) {
        formContext.updateValue(getDialogValueKey(data), 'false')
      }
      else {
        formContext.updateValue(getDialogValueKey(data), 'true')
      }

      formContext?.resuablePropertyChanged(data.property, currentValueTemp ? data.valueLabel : '')

      setValue(!currentValueTemp)
      setCurrentValue(!currentValueTemp)
    }

    
  }
  else {
    currentValueTemp = data.defaultCheckedState;
      onChange = () => {
        setValue(!value);
        setCurrentValue(!value)
      }
  }

  const [currentValue, setCurrentValue] = useState(currentValueTemp);

  useEffect(() => {
    if(formContext) {
      const formValue = formContext?.inputValues[getDialogValueKey(data)]?.value
      setCurrentValue((formValue?.toLowerCase() === 'true' ? true : false))
      formContext?.resuablePropertyChanged(data.property, formValue?.toLowerCase() === 'true' ? data.valueLabel : '')
    }
  }, [formContext?.inputValues[getDialogValueKey(data)]])

  const isChecked = isEditMode ? data.defaultCheckedState : currentValue
  const id = getElementKey(data)
  const isDescriptionHidden = data?.hideLabel ?? false;

  return (
    <div className='lg:flex items-end w-full'>
    <InlineActionbarElementWrapper designElement={elementDesign} additionalClasses="w-full" openElementProperties>
      <div
        className={`flex items-center w-full`}
      >
        <div className={`relative flex items-start w-full ${inputErrorClasses ?? ''} pl-1 pt-0.5`}>
          <div className="flex items-center h-5">
            {
              isPdfMode ?
              <label className="-mr-2"> 
                {
                  currentValue ? 
                  <span style={{fontFamily: "Verdana"}} className="text-2xl" dangerouslySetInnerHTML={{ __html: '&#x2611;' }} /> 
                  : 
                  <span style={{fontFamily: "Verdana"}} className="text-2xl" dangerouslySetInnerHTML={{ __html: '&#x2610;' }} />
                }
              </label>        
              :
              <input
                id={id}
                aria-describedby={`${classPrefix}-description`}
                name="checkbox"
                type="checkbox"
                style={{ color: backgroundColorCheckbox }}
                className="sf-checkbox-input h-4 w-4 text-red-600 border-gray-300 rounded px-0 focus:ring-4 active:ring-4 ring-indigo-300"
                checked={isChecked}
                defaultChecked={currentValue}
                // value={value}
                onChange={onChange}
                disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
              />
            }
          </div>
          <div className={`${isEditMode ? 'flex flex-col' : ''} ml-3 text-sm w-full`}>
            <InlineEditing
              initData={data}
              style={labelStyle}
              classes={`sf-checkbox-label ${labelClass}`}
              name='valueLabel'
            >
              <label htmlFor={id} style={labelStyle} className={`sf-checkbox-label break-words ${labelClass}`}>
                {elementDesign.translateTerm(data.valueLabel, "valueLabel")}
                {elementDesign.translateHelpText(data)}
                {elementDesign.requiredFieldIndicator(data.requiredField)}
              </label>
            </InlineEditing>
            <div className={`${isDescriptionHidden ? 'hidden' : ''}`}>
              <InlineEditing
                initData={data}
                style={descriptionStyle}
                classes={`sf-checkbox-label ${descriptionClass}`}
                name='label'
              >
                <p
                  id={`${classPrefix}-description`}
                  style={descriptionStyle}
                  className={`sf-checkbox-label break-words ${descriptionClass}`}
                >
                  {elementDesign.translateTerm(data.label, 'description')}
                </p>
              </InlineEditing>
            </div>
          </div>
        </div>
      </div>
    </InlineActionbarElementWrapper>
    {
      (isChecked || isEditMode) && data?.connectedElements?.map((element, index) => {
        return (
          <div key={index} className="ml-3">  
            <ElementFromType isConnectedElement={true} isParentReadOnly={multiStep.isReadOnly} element={element} /> 
          </div>
        )
      })
    }
    </div>
  )
}