import { countryList } from "./countryList";

export const adminProductId = "611b8ac465064876fb35e1cf";
export const designFeatureCode = "DSF";
export const dataFeatureCode = "DDF";
export const integrationCode = "DIN";
export const templateFeatureCode = "DTF";

export const designContexts = {
  element: 'element',
  row: 'row',
  column: 'column',
  container: 'container',
}

export const editStates = {
  published: 0,
  draft: 1
}

export const dataLoadStates = {
  imported: 1,
  dialogsGenerated: 5,
  active: 10,
  notfied: 11
}
export const dataLoadNotificationStates = {
  notSet: 1,
  draft: 5,
  issued: 10
}

export const dataLoadTypes = {
  localFile: 1,
  ftp: 2,
  api: 3,
  crm: 4
}

export const elementTypes = {
  textLine: 0,
  textArea: 1,
  inputTextLine: 2,
  headerLine: 3,
  bitmatp: 4,
  datePicker: 5,
  button: 6,
  switch: 7,
  divider: 8,
  radioList: 9,
  radioListAsGroup: 11, //We are not using this anymore
  fileUpload: 12,
  radioButtons: 13, //We are not using this anymore
  disclaimer: 14,
  dropDown: 15,
  flowSelector: 16,
  checkbox: 17,
  cancelButton: 18,
  signature: 19,
  timePicker: 20,
  attachment: 21,
  video: 22,
  hyperLink: 23,
  richText: 24,
  dynamicList: 26,
  radioButtonGroup: 27,
  spacer: 28,
  orderTable: 29,
  smartTable: 30,
  resource: 31,
  matrix: 32,
  map: 33
}

export const dialogDefinitionStatuses = {
  active: 0,
  inactive: 1,
}

export const dialogObjectStatuses = {
  active: 0,
  inactive: 1,
}

export const webhookStatuses = {
  active: 0,
  inactive: 1,
}

export const webhookTypes = {
  manual: 0,
}

export const webhookSources = {
  smartForms: 0,
  zapier: 1,
}

export const securityType = {
  none: 1,
  email: 2,
  phone: 3,
  bankId: 4,
}

export const securityTypeOptions = [
  {
    value: securityType.none,
    name: 'None',
  },
  {
    value: securityType.email,
    name: 'Email',
  },
  {
    value: securityType.phone,
    name: 'SMS',
  },
  {
    value: securityType.bankId,
    name: 'BankId',
  },
]

export const processTab = {
  processSteps: 'process-steps',
  parties: 'parties',
  digitalSigning: 'digital-signing'
}

export const processTypes = {
  normal: "Normal",
  multiStep: "Multi-Step",

  /**
   * Delete. Replaced by mutli-step + phone auth + contact list
   */
  phoneNumber: "Phone number"
}

export const processTypeOptions = [
  {
    value: processTypes.normal,
    name: "Normal",
  },
  {
    value: processTypes.multiStep,
    name: "Process",
  }
]

export const dialogValuesStepStatus = {
  notStarted: 1,
  inProgress: 2,
  completed: 3,
}

export const validateDataTypes = {
  text: 0,
  number: 1,
  email: 2,
  SSN: 3,
  Api: 4,
  BBAN: 5
}

export const validateDataDefaultMessage = {
  0: "only text",
  1: "only number",
  2: "only email",
  3: "invalid SSN",
  4: "invalid API",
  5: "invalid BBAN"
}

export const validateDataTypeOptions = [
  {
    value: validateDataTypes.text,
    name: 'Text',
  },
  {
    value: validateDataTypes.number,
    name: 'Number',
  },
  {
    value: validateDataTypes.email,
    name: 'Email',
  },
  {
    value: validateDataTypes.SSN,
    name: 'SSN',
  },
  {
    value: validateDataTypes.Api,
    name: 'Api',
  },
  {
    value: validateDataTypes.BBAN,
    name: 'BBAN'
  }
]

export const resourceCategories = {
  element: 1,
  section: 2
}
export const resourceCategoryNames = {
  [resourceCategories.element]: 'Element',
  [resourceCategories.section]: 'Section'
}

export const countries = {
  none: 0,
  norway: 0,
  sweden: 1,
  finland: 2,
  denmark: 3
}

export const countriesOptions = [
  {
    value: countries.norway,
    name: 'Norway',
  },
  {
    value: countries.sweden,
    name: 'Sweden',
  },
  {
    value: countries.finland,
    name: 'Finland',
  },
  {
    value: countries.denmark,
    name: 'Denmark',
  },
];

export const templateLanguages = countryList.map(language => ({
  ...language,
  value: language.code,
  name: `${language.name} (${language.nativeName})`,
  nativeName: language.nativeName,
  standardName: language.name,
  code: undefined
}))

export const dependenciesType = {
  none: 0,
  selected: 1
}

export const smartObjectPropertyTypes = {
  string: 0,
  number: 1,
  dropdown: 2,
  checkbox: 3,
  date: 4
}

export const dependenciesOptions = [
  {
    value: dependenciesType.none,
    name: 'None',
  },
  {
    value: dependenciesType.selected,
    name: 'Selected',
  },
]

export const notificationEventType = {
  notification: 1,
  securityCode: 2,
  processStep: 3,
  completedWithPdf: 4,
  reminder: 5,
}

export const authenticationTemplateStatus = {
  active: 10,
  disabled: 99
}

export const notificationTemplateStatus =
{
  active: 10,
  disabled: 99
}

export const MegaByte = 1048576

export const tableInputTypes =
{
  inputTextLine: 0,
  dropdown: 1
}

export const tableValueTypes =
{
  number: 0,
  percent: 1
}

export const dialogType = {
  normal: 1,
  showAndHide: 2,
  stepByStep: 3,
  standardWTable: 4
}

export const dialogTypeOptions = [
  {
    value: dialogType.normal,
    name: "Normal",
  },
  {
    value: dialogType.stepByStep,
    name: "Step by step"
  },
  {
    value: dialogType.standardWTable,
    name: "Standard w/Table"
  }
]

export const positionTypes = {
  top: 0,
  left: 1
}

export const positionOptions = [
  {
    value: positionTypes.top,
    name: "Top",
  },
  {
    value: positionTypes.left,
    name: "Left"
  }
]

export const backgroundSizeTypes = {
  auto: 0,
  contain: 1,
  cover: 2,
}

export const backgroundSizeOptions = [
  {
    name: "Auto",
    value: backgroundSizeTypes.auto,
  },
  {
    name: "Contain",
    value: backgroundSizeTypes.contain,
  },
  {
    name: "Cover",
    value: backgroundSizeTypes.cover,
  }
]

export const dialogDefinitionReminderMethods = {
  email: 0,
  sms: 1
}

export const alignmentOptions = [
  { name: "Left", value: 'left' },
  { name: "Center", value: 'center' },
  { name: "Right", value: 'right' }
]

export const dialogSizeTypes = {
  small: 0,
  medium: 1,
  large: 2
}

export const conditionTypes = {
  textMatch: 0,
  contain: 1,
  different: 2
}

export const conditionTypesByElementType = (elementType) => {
  switch (elementType) {
    case elementTypes.inputTextLine:
      return ["TextMatch", "Contain", "TextDifferent"]
    case elementTypes.dropDown:
      return ["OptionMatch", null, "OptionDifferent"]
    case elementTypes.radioList:
      return ["OptionMatch", null, "OptionDifferent"]
    case elementTypes.radioButtonGroup:
      return ["OptionMatch", null, "OptionDifferent"]
    case elementTypes.checkbox:
      return ["CheckMatch"]
    default:
      return []
  }
}

export const conditionConectorTypes = {
  none: 0,
  and: 1,
  or: 2
}

export const errorMessages = {
  required: "Required",
  maxLimit: (maxAmount) => `Max length: ${maxAmount}`
}

export const dataMigrationStatuses = {
  queued: 1,
  running: 2,
  complete: 3,
  failed: 4,
}

export const licenses = {
  smartForms: '611b8ac465064876fb35e1cf'
}

export const contactListStatuses = {
  active: 1,
  disabled: 2,
}

export const languageListStatus = {
  activeDefault: 1,
  active: 2,
  disabled: 3,  
}

export const smartFormEnvironments = {
  development: 1,
  test: 5,
  preProd: 8,
  production: 10,
}

export const smartFormEnvironmentNames = {
  [smartFormEnvironments.development]: 'Development',
  [smartFormEnvironments.test]: 'Test',
  [smartFormEnvironments.preProd]: 'Pre Production',
  [smartFormEnvironments.production]: 'Production',
}

export const defaultTextSize = 16
export const defaultLabelSize = 16;

export const allFileTypes = [
  ".txt",
  ".doc",
  ".docx",
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".svg",
  ".mp3",
  ".wav",
  ".ogg",
  ".mp4",
  ".avi",
  ".mkv",
  ".xls",
  ".xlsx",
  ".csv",
  ".zip",
  ".rar",
  ".ppt",
  ".pptx"
]

export const allFileTypesOptions = [
  {
    value: ".txt",
    label: ".txt"
  },
  {
    value: ".doc",
    label: ".doc"
  },
  {
    value: ".docx",
    label: ".docx"
  },
  {
    value: ".pdf",
    label: ".pdf"
  },
  {
    value: ".jpg",
    label: ".jpg"
  },
  {
    value: ".jpeg",
    label: ".jpeg"
  },
  {
    value: ".png",
    label: ".png"
  },
  {
    value: ".gif",
    label: ".gif"
  },
  {
    value: ".bmp",
    label: ".bmp"
  },
  {
    value: ".svg",
    label: ".svg"
  },
  {
    value: ".mp3",
    label: ".mp3"
  },
  {
    value: ".wav",
    label: ".wav"
  },
  {
    value: ".ogg",
    label: ".ogg"
  },
  {
    value: ".mp4",
    label: ".mp4"
  },
  {
    value: ".avi",
    label: ".avi"
  },
  {
    value: ".mkv",
    label: ".mkv"
  },
  {
    value: ".xls",
    label: ".xls"
  },
  {
    value: ".xlsx",
    label: ".xlsx"
  },
  {
    value: ".csv",
    label: ".csv"
  },
  {
    value: ".zip",
    label: ".zip"
  },
  {
    value: ".rar",
    label: ".rar"
  },
  {
    value: ".ppt",
    label: ".ppt"
  },
  {
    value: ".pptx",
    label: ".pptx"
  },
]

export const allowedPrefixes = ["+47"]

export const bitmapPositionTypes = {
  top: 0,
  bottom: 1,
  left: 2,
  right: 3
}

export const bitmapPositionOptions = [
  {
    value: bitmapPositionTypes.top,
    name: "Top",
  },
  {
    value: bitmapPositionTypes.bottom,
    name: "Bottom"
  },
  {
    value: bitmapPositionTypes.left,
    name: "Left"
  },
  {
    value: bitmapPositionTypes.right,
    name: "Right"
  }
]

export const systemIntegrations = {
  salesforce: 1,
}

export const processValues = {
  header: "Information",
  description: "This form is a part of a process and involves these steps and persons:",
  step: "Step",
  current: "Current",
  signingDescription: "Upon completion of the process, the form will require digital signature",
  signatory: "Signatory:",
}