import { useContext, useEffect } from "react";
import { DialogModes, FormContext } from "../../contexts";
import { InlineActionbarElementWrapper } from "../../components/InlineActionbarElementWrapper";
import { useElementDesign } from "../useElementDesign";
import { useElementStyle } from "../useElementStyle";
import { useMultiStep } from "../../hooks/useMultiStep";
import { getDialogValueKey } from "../../utils/features";
import { useState } from "react";
import _ from "lodash";
import { useDebounceCallback } from "@react-hook/debounce";
import { getInformationWorkflow } from "../../api/apiDefinition";
import { useParams } from "react-router";

export default function Matrix({ data, ...props }) {
  const formContext = useContext(FormContext)
  const { isEditMode } = useContext(DialogModes)
  const { dialogKey, valuesKey } = useParams();
  const elementDesign = useElementDesign(data.id, data)
  const elementStyle = useElementStyle(data)
  const multiStep = useMultiStep(data)

  const [value, setValue] = useState({})

  const labelClass = elementStyle.textClassName(data?.labelStyle)
  const labelStyle = elementStyle.textStyle(data?.labelStyle)

  let currentValue;
  let onChange;

  const getInformationFromWorkflow = useDebounceCallback((value, workflowRetrieveId) => {
    const execute = async () => {
      var response = await getInformationWorkflow(dialogKey, valuesKey, workflowRetrieveId, [{ property: data.property, value: value }])

      response.forEach(element => {
        if (!element?.preventDataInsert)
          formContext?.updateByPropertyNameValue(element.property, element.value)
      });
    }

    execute();
  },
    500);


  if (formContext) {
    currentValue = formContext?.inputValues[getDialogValueKey(data)] || {}

    // initialize if not set
    if (!currentValue?.matrix?.selections) {
      _.set(currentValue, 'matrix.selections', [])
    }

    onChange = (e) => {
      const questionId = e.target.name.split(`${getDialogValueKey(data)}-`)[1];
      const optionId = e.target.value;
      const selectionIndex = currentValue.matrix.selections.findIndex(q => q.matrixQuestionId === questionId)
      const nextValue = _.cloneDeep(currentValue)
      if (selectionIndex >= 0) {
        nextValue.matrix.selections[selectionIndex].matrixOptionId = optionId
      }
      else {
        const selection = { matrixQuestionId: questionId, matrixOptionId: optionId }
        nextValue.matrix.selections.push(selection)
      }

      if (data.workflowRetrieveId) {
        
        const selections = nextValue.matrix.selections;
        const options = data.matrix.options;
        const questions = data.matrix.questions;
        const formattedValue = JSON.stringify({ questions, options, selections })
        getInformationFromWorkflow(formattedValue, data.workflowRetrieveId);
      }
      formContext.updateFormValueProperty(getDialogValueKey(data), "matrix", nextValue.matrix)
    }
  } else {
    currentValue = value
    if (!currentValue?.matrix?.selections) {
      _.set(currentValue, 'matrix.selections', [])
    }
    onChange = (e) => {
      const questionId = e.target.name.split(`${getDialogValueKey(data)}-`)[1];
      const optionId = e.target.value;
      const selectionIndex = currentValue.matrix.selections.findIndex(q => q.matrixQuestionId === questionId)
      if (selectionIndex >= 0) {
        currentValue.matrix.selections[selectionIndex].matrixOptionId = optionId
      }
      else {
        const selection = { matrixQuestionId: questionId, matrixOptionId: optionId }
        currentValue.matrix.selections.push(selection)
      }
      setValue({
        ...currentValue
      })
    }
  }

  const options = data?.matrix?.options || []
  const questions = data?.matrix?.questions || []
  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div className="flex lg:max-w-full max-w-sm">
        <div className="w-full overflow-x-auto">
          <div className="max-w-full">
            <table className="w-full">
              <thead>
                <tr className="">
                  <th />
                  {
                    data?.matrix?.showRowNumber &&
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                    </th>
                  }
                  {
                    options.map((o, index) => {
                      return (
                        <td className="px-6 py-1.5" key={`option-header-${o.id}`}>
                          <span className={labelClass} style={labelStyle}>{elementDesign.translateMatrixOption(o.id, o.label)}</span>
                        </td>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody className="">
                {
                  questions.map((q, index) => {
                    return (
                      <tr key={`${getDialogValueKey(data)}-question-${q.id}`} className="" onChange={onChange}>
                        {
                          data?.matrix?.showRowNumber &&
                          <td className="pr-3 py-2 whitespace-nowrap text-sm align-top font-bold">
                            {index + 1}
                          </td>
                        }
                        <td className="pr-4 py-1.5"><span className={labelClass} style={labelStyle}>{elementDesign.translateMatrixQuestion(q.id, q.label)}</span></td>
                        {
                          options.map(o => {
                            return (
                              <td className="text-center py-1.5">
                                <input
                                  type="radio"
                                  className="cursor-pointer"
                                  name={`${getDialogValueKey(data)}-${q.id}`}
                                  value={o.id}
                                  checked={currentValue?.matrix?.selections?.find(s => s.matrixQuestionId === q.id)?.matrixOptionId === o.id && !isEditMode}
                                  disabled={elementDesign.isReadOnly() || multiStep.isReadOnly || isEditMode}
                                />
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="ml-2">
          {elementDesign.requiredFieldIndicator(data.requiredField)}
        </div>
      </div>

    </InlineActionbarElementWrapper >
  )
}